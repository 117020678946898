/*
    Flaticon icon font: Flaticon
    Creation date: 05/07/2018 04:38
    */

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/flaticon/font/Flaticon.eot");
  src: url("../fonts/flaticon/font/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/flaticon/font/Flaticon.woff") format("woff"),
       url("../fonts/flaticon/font/Flaticon.ttf") format("truetype"),
       url("../fonts/flaticon/font/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: "Flaticon";
        src: url("../fonts/flaticon/font/Flaticon.svg#Flaticon") format("svg");
    }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-car:before { content: "\f100"; }
.flaticon-resort:before { content: "\f101"; }
.flaticon-yatch:before { content: "\f102"; }
.flaticon-map-of-roads:before { content: "\f103"; }
.flaticon-compass:before { content: "\f104"; }
.flaticon-around:before { content: "\f105"; }
.flaticon-sailboat:before { content: "\f106"; }