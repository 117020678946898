/* DestinationSearchArea.css */
.destination-search-area {
    position: relative;
}

.selected-destination {
    display: flex;
    align-items: center;
    background-color: #ff5a5f; /* Match the site's red theme */
    color: white;
    padding: 10px;
    border: 1px solid white; /* Ensures the border is white */
    border-radius: 5px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.chalet-icon {
    margin-left: 10px;
}

.popover-container {
    width: 50vh;
    height: 30vh;
    min-height: 100px;
    max-height: 60vh;
    overflow-y: auto;
    border-width: 1px;
    border-color: #ddd;
    border-style: solid;
}

/* Media Queries */
@media (max-height: 800px) {
    .popover-container {
        max-height: 50vh;
    }
}

@media (max-height: 600px) {
    .popover-container {
        max-height: 40vh;
    }
}

@media (max-height: 400px) {
    .popover-container {
        max-height: 30vh;
    }
}

.chalet-icon {
    margin-right: 10px;
    color: white;
}
