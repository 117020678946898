.DateInput_input {
    color: white !important; /* Ensure the input text color is white */
    background-color: #ff5a5f !important; /* Match input background color to site's theme */
    border: none !important; /* Remove the default border */
    width: 100%; /* Ensure the input spans the full width */
    padding-left: 10px; /* Adjust padding as needed */
}

.date-picker-container {
    background-color: #ff5a5f; /* Match the site's red theme */
    color: white;
    padding: 10px;
    border: 1px solid white; /* Ensures the border is white */
    border-radius: 5px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.date-picker-label {
    cursor: pointer;
    font-size: 16px;
    color: white; /* Ensure the text color is white for visibility */
    width: 100%;
    text-align: center;
}

.DateRangePicker {
    width: 100%;
}

.DateRangePickerInput {
    background-color: #ff5a5f !important; /* Match the site's red theme */
    color: white !important; /* Ensure the input text color is white */
    width: 100%;
    border: none; /* Remove the default border */
    display: flex;
    justify-content: space-between; /* Ensure the input spans the full width */
}

.DateInput {
    width: 100%; /* Ensure each date input spans equally */
}

/* Increase specificity and use !important */
.DateInput_input,
.DateInput_input__focused {
    color: white !important; /* Ensure the input text color is white */
    background-color: #ff5a5f !important; /* Match input background color to site's theme */
    border: none !important; /* Remove the default border */
    width: 100%; /* Ensure the input spans the full width */
    padding-left: 10px; /* Adjust padding as needed */
}

/* Additional styling for focused state if needed */
.DateInput_input__focused {
    color: white !important; /* Ensure the input text color remains white when focused */
}

.DateRangePickerInput_arrow_svg {
    fill: white !important;
}


.DateInput_input::placeholder {
    color: white;
}