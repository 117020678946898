/*SKI COLOR SCHEME */
/* #19647E
#28AFB0 
#37392E
#DDCECD 
#EEE5E5 */

search-form{
    /* background-image: url("resources/images/searchbackground.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
}

.searchBoxHolder{
    background: #19647E;
    /* background: rgba(1,175,217, 0.8); */
    /* background: rgba(255, 255, 255, 0.58); */
}

.searchBoxHolder label {

    color: #fff;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
}

.react-datepicker-wrapper {
    width: 100%;
}

.nav-pills .nav-link {
    color: black; /* Text color is black for inactive links */
    text-decoration: none; /* Removes underline */
}

    .nav-pills .nav-link:hover {
        color: black; /* Keeps text black on hover for inactive links */
    }

    .nav-pills .nav-link.active {
        color: white !important; /* Text color is white for the active link */
    }




.search-title {
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}