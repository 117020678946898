.booking-process {
    margin: 20px auto 26px;
    list-style: none;
}

    .booking-process li i {
        display: block;
        height: 68px;
        width: 68px;
        text-align: center;
        margin: 0 auto;
        background: #f5f6f7;
        border: 2px solid #e5e5e5;
        line-height: 65px;
        font-size: 30px;
        border-radius: 50%;
    }

    .booking-process li {
        display: inline-block;
        width: 14.2%;
        text-align: center;
        float: none;
    }


        .booking-process li a {
            padding: 0;
            border: none;
            color: #606060;
        }


            .booking-process li a:focus,
            .booking-process li a:hover,
            .booking-process li.active a,
            .booking-process li.active a:hover,
            .booking-process li.active a:focus,
            .booking-process li.visited a,
            .booking-process li.visited a:hover,
            .booking-process li.visited a:focus {
                color: #ff5f5f;
            }


@media screen and (max-width: 560px) {
    .booking-process li {
        width: 100%;
    }
}



