.chaletFacilities {
    min-height: 250px;
}

.owl-carousel.chalet-slider {
    z-index: 0;
    position: relative;
}

    .owl-carousel.chalet-slider .slider-item {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        /*  height: calc(100vh - 600px);*/
        height: 50vh;
        min-height: 500px;
        position: relative;
    }

        .owl-carousel.chalet-slider .slider-item .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            background: #000;
            opacity: .2;
        }

        .owl-carousel.chalet-slider .slider-item .slider-text {
            color: #fff;
            height: calc(100vh - 400px);
            width: 100%;
            /*min-height: 700px;*/
        }

            .owl-carousel.chalet-slider .slider-item .slider-text h1 {
                font-size: 72px;
                color: #fff;
                line-height: 1.2;
                font-weight: 400;
            }

@media (max-width: 991.98px) {
    .owl-carousel.chalet-slider .slider-item .slider-text h1 {
        font-size: 40px;
    }
}

.owl-carousel.chalet-slider .slider-item .slider-text p {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.8);
}

    .owl-carousel.chalet-slider .slider-item .slider-text p strong {
        font-weight: 700;
    }

        .owl-carousel.chalet-slider .slider-item .slider-text p strong a {
            color: #fff;
        }

.owl-carousel.chalet-slider .slider-item .slider-text .breadcrumbs {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    margin-bottom: 0;
}

    .owl-carousel.chalet-slider .slider-item .slider-text .breadcrumbs span {
        border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    }

        .owl-carousel.chalet-slider .slider-item .slider-text .breadcrumbs span a {
            color: #fff;
        }

.owl-carousel.chalet-slider .owl-nav .owl-prev span,
.owl-carousel.chalet-slider .owl-nav .owl-next span {
    color: #fff;
}

.owl-carousel.chalet-slider .owl-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;
    width: 100%;
    text-align: center;
}

    .owl-carousel.chalet-slider .owl-dots .owl-dot {
        width: 18px;
        height: 18px;
        margin: 5px;
        border-radius: 50%;
        background: #e6e6e6;
        background: none;
        border: 2px solid rgba(255, 255, 255, 0.5);
        outline: none !important;
        position: relative;
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease;
        display: inline-block;
    }

        .owl-carousel.chalet-slider .owl-dots .owl-dot span {
            position: absolute;
            width: 6px;
            height: 6px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 50% !important;
            left: 50%;
            top: 50%;
            display: block;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }

        .owl-carousel.chalet-slider .owl-dots .owl-dot.active {
            border: 2px solid white;
        }

            .owl-carousel.chalet-slider .owl-dots .owl-dot.active span {
                background: white;
            }











/*-------------Chalet Calendar------------------*/


.btn-Availability-Calendar {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: white;
}

    .btn-Availability-Calendar:disabled, button[disabled]:hover {
        cursor: not-allowed;
        background-color: white;
        color: lightgrey;
    }

    .btn-Availability-Calendar:disabled, button[disabled] {
        cursor: not-allowed;
        color: lightgrey;
        background-color: white;
    }

.hiddenCalendar {
    display: none;
}

.chaletCalender {
    padding-bottom: 15px;
}
/* Style the active class, and buttons on mouse-over */
.btn-Availability-Calendar:hover {
    background-color: #666;
    color: white;
}


.fromDate {
    background-color: #ff5f5f;
}

.toDate {
    background-color: #ff5f5f;
}

.midDate {
    background-color: #ffc1c1;
}

.notValidDate {
    cursor: not-allowed !important;
    color: lightgrey;
    background-color: white;
}

.validDate {
    background-color: #c1ffc1;
}

.chaletBookingOption {
    border: 1px solid black;
}




.summaryOptions {
    text-align: left;
}

.summaryCosts {
    text-align: left;
}

.summaryboxes {
    font-weight: 900;
}

.ChaletTabs
{
    min-height:500px;
}

.padding-ten {
    padding: 10px;
}
