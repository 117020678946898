a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}




.block-17 #container, .block-17 .search-submit {
    min-height: 52px;
    height: 52px !important;
}

.block-17 #container, svg {
    stroke: white;
}


.block-17 form .fields .select-wrap option {
    color: black;
}

.qty {
    color: #FFFFFF;
    text-align: center;
    width: 100%;
}


.counterInput {
    background-color: #ff5f5f;
    color: white;
    -webkit-text-fill-color: white;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    pointer-events: none;
    display: inline-block;
    vertical-align: top;
    font-size: 20px;
    line-height: 30px;
    padding-right: 2px;
    padding-top: 0;
    min-width: 35px;
    text-align: center;
}


#container {
    color: white;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.white-border {
    box-shadow: none !important;
    border: 2px solid rgba(255, 255, 255, 0.5);
    font-size: 16px;
    width: 100%;
    height: 52px;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.searchbar-btn {
    height: 52px;
}
/*.searchBarDate
{
    min-width:150px;
}
*/


.numbersCounter .minus,
.numbersCounter .plus {
    background-color: #f5f6f7;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    color: #000000;
    width: 30px;
    height: 30px;
    font: 25px/1 Arial,sans-serif;
    text-align: center;
    border-radius: 50%;
    background-clip: padding-box;
    padding-top: 3px;
}

.minus:hover {
    background-color: #ccc6c6 !important;
}

.plus:hover {
    background-color: #ccc6c6 !important;
}

input .counter {
    border: 0;
    width: 2%;
}

.count-room, .count-guest {
    border: 0;
    width: 40px;
}

.minus-room, .minus-guest {
}

nput::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}





.react-daterange-picker, .react-daterange-picker__inputGroup, .react-daterange-picker__wrapper, .react-daterange-picker__inputGroup__input, .react-daterange-picker__inputGroup {
    color: white;
}

.react-daterange-picker__wrapper {
    border: none !important;
    padding-top: 5px;
}


.react-daterange-picker__clear-button react-daterange-picker__button {
    color: white;
}

.react-daterange-picker__clear-button {
    padding-top: 1px;
}


.navBarSearchButton-bar {
    padding-top: 25px;
}

.react-calendar {
    color: black;
}

.react-daterange-picker__calendar {
    width: 700px !important;
}


.searchbarDropDown {
    box-shadow: none !important;
    border: 2px solid rgba(255, 255, 255, 0.5);
    font-size: 16px;
    width: 100%;
    height: 52px;
}

div[id^="obj_"] {
    background: #000
}

.searchBarDDL__control {
    box-shadow: none !important;
    /*  border: 2px solid rgba(255, 255, 255, 0.5);*/
    background: transparent !important;
    font-size: 16px;
    width: 100%;
    height: 52px;
    color: white;
}

.searchBarDDL__single-value, .searchBarDDL__placeholder, .searchBarDDL__indicator {
    color: white !important;
}

/*font Size changes for nav menu */
@media (min-width: 576px) {
    .searchBar {
        font-size: 8px !important;
    }
}

@media (max-width: 767px) {
    .counterInput {
        font-size: 18px;
        padding: 0 3px;
    }

    .numbersCounter .plus,
    .numbersCounter .minus {
        width: 28px;
        height: 28px;
        font-size: 22px;
    }
}


@media (min-width: 1200px) {
    .searchBar {
        font-size: 16px !important;
    }
}

/*Header*/
.header-static-container {
    z-index: 0;
    position: relative;
}

    .header-static-container .static-image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        height: calc(100vh - 117px);
        min-height: 700px;
        position: relative;
    }

        .header-static-container .static-image .header-text {
            color: #fff;
            height: calc(100vh - 117px);
            min-height: 700px;
        }

            .header-static-container .static-image .header-text h1 {
                font-size: 72px;
                color: #fff;
                line-height: 1.2;
                font-weight: 400;
            }

            .header-static-container .static-image .header-text .breadcrumbs {
                text-transform: uppercase;
                font-size: 13px;
                letter-spacing: 1px;
                margin-bottom: 0;
            }

                .header-static-container .static-image .header-text .breadcrumbs span {
                    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
                }

                    .header-static-container .static-image .header-text .breadcrumbs span a {
                        color: #fff;
                    }
