
#ca-ftco-section-search {
    padding-top: 4px;
}

/*/*#tempOwlHeader {
    background-image: url(../images/bg_1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: calc(100vh - 117px);
    min-height: 700px;
    position: relative;
}*/
#bg_1 {
    background-image: url(../images/homepage-background/bckgd_1.jpg);
}

#bg_2 {
    background-image: url(../images/homepage-background/bckgd_2.jpg);
}

#bg_3 {
    background-image: url(../images/homepage-background/bckgd_3.jpg);
}

#bg_4 {
    background-image: url(../images/homepage-background/bckgd_4.jpg);
}

#bg_5 {
    background-image: url(../images/homepage-background/bckgd_5.jpg);
}

#bg_6 {
    background-image: url(../images/homepage-background/bckgd_6.jpg);
}


#bg_7 {
    background-image: url(../images/homepage-background/bckgd_7.jpg);
}

#bg_8 {
    background-image: url(../images/homepage-background/bckgd_8.jpg);
}

#bg_9 {
    background-image: url(../images/homepage-background/bckgd_9.jpg);
}

#bg_10 {
    background-image: url(../images/homepage-background/bckgd_10.jpg);
}

#bg_11 {
    background-image: url(../images/homepage-background/bckgd_11.jpg);
}

#bg_12 {
    background-image: url(../images/homepage-background/bckgd_12.jpg);
}

#bg_13 {
    background-image: url(../images/homepage-background/bckgd_13.jpg);
}







.owl-dots {
    padding-bottom: 50px;
}

#main-image {
    background-image: url(../images/main-image.jpg);
}


#about-main-image {
    background-image: url(../images/About-Main.jpg);
}


#tour_1 {
    background-image: url(../images/tour/tour-1.jpg);
}

#tour_2 {
    background-image: url(../images/tour/tour-2.jpg);
}

#tour_3 {
    background-image: url(../images/tour/tour-3.jpg);
}

#tour_4 {
    background-image: url(../images/tour/tour-4.jpg);
}

#tour_5 {
    background-image: url(../images/tour/tour-5.jpg);
}

#tour_6 {
    background-image: url(../images/tour/tour-6.jpg);
}

#tour_7 {
    background-image: url(../images/tour/tour-7.jpg);
}

#tour_8 {
    background-image: url(../images/tour/tour-8.jpg);
}




#promo_1 {
    background-image: url(../images/promo-1.jpg);
}

#promo_2 {
    background-image: url(../images/promo-2.jpg);
}


#promo_3 {
    background-image: url(../images/promo-3.jpg);
}


#promo_4 {
    background-image: url(../images/promo-4.jpg);
}


#hotel-1 {
    background-image: url(../images/hotel-1.jpg);
}

#hotel-2 {
    background-image: url(../images/hotel-2.jpg);
}

#hotel-3 {
    background-image: url(../images/hotel-3.jpg);
}


.contact_back {
    background: white;
}


.blog-Hotel-1 {
    background-image: url(../images/hotel-1.jpg);
}

#blog-Hotel-2 {
    background-image: url(../images/hotel-2.jpg);
}

#blog-Hotel-3 {
    background-image: url(../images/hotel-3.jpg);
}



.maps {
    height: 100%;
    width: 100%;
}

#chaletMap {
/*    height: 800px;*/
    width: 100%;
}

.chaletMapSection {
    min-height: 600px;
}


#contactMap {
    height: 400px;
}

#countriesMap {
    height: 100%;
}
/*-------------Test Areas------------------*/
#TestArea {
    height: 500px;
    background-color: deeppink;
}

.TestIndent {
    text-indent: 10em;
    text-indent: 25px;
    text-align: right;
    color: yellow;
    width: 500px;
}

/*.country {
    background-color: yellow;
    font-weight: bold;
}

#country {
    background-color: yellow;
    font-weight: bold;
}*/

.icon-checkmark:before {
    content: "\2713";
}

.refiner-divider {
    border-top: 1px solid #ddd; /* light grey color */
    margin: 10px 0; /* space above and below */
}

.refiner-section:nth-child(even) {
    background-color: #f9f9f9; /* light grey */
    padding-left: 20px;
    padding-right: 30px;
    padding-bottom: 20px;
}

.refiner-heading {
    font-weight: bold;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.home-divider {
    margin: 25px 0; /* space above and below */
}



/*BootstrapGrid*/

.contentSpacing {
    margin-top: 5px;
}

@media (max-width: 767px) {
    .seven-cols .col-md-1,
    .seven-cols .col-sm-1,
    .seven-cols .col,
    .seven-cols .col-lg-1 {
        width: 14.285714285714285714285714285714%;
        *width: 14.285714285714285714285714285714%;
        padding-right: 2px;
        padding-left: 2px;
        font-size: smaller;
    }
}

@media (max-width: 767px) {
    .summaryboxes .ml-1,
    .summaryboxes .mt-1 {
        margin: 0px;
    }
}



.nowrapclass {
    white-space: nowrap;
}

.leftsummary {
    border-right-color: #ff5f5f;
    border-right-style: solid;
    border-right-width: 2px;
}








.test1 {
    background-color: orange;
}

.test2 {
    background-color: blue;
}

.test3 {
    background-color: green;
}

.test4 {
    background-color: yellow;
}


.test44 {
    height: 500px;
    width: 500px;
}

.test55 {
    height: 500px;
    width: 500px;
}


.test66 {
    height: 3000px;
    width: 1000px;
}

/*

.resultsOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: pointer;
}*/

.resultsOverlay-hidden {
    display: none;
    height: 0px;
    width: 0px;
}



.resultsOverlay {
    position: absolute;
    background-color: rgba(0,0,0,0.6);
    /*dim the background*/
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: black;
    text-align: center;
    z-index: 5;
}

.hideMe {
    display: none;
}



.contact-social li {
    list-style: none;
    margin: 0 10px 10px 0;
    display: inline-block;
}

    .contact-social li a {
        height: 50px;
        width: 50px;
        display: block;
        float: left;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 50%;
        position: relative;
    }

        .contact-social li a span {
            position: absolute;
            font-size: 26px;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }

        .contact-social li a i {
            position: absolute;
            font-size: 26px;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }

        .contact-social li a:hover {
            color: #17a2b8;
        }
