body {
}


@media (min-width: 768px) {
    .modal-xl {
        width: 90%;
        max-width: 1200px;
        height:500px;
    }
}


.imageCarousel nav:hover {
    color: #ff3939;
}




.imageCarousel .enabled {
    color: green;
}

    .imageCarousel .enabled:hover {
        color: #ff3939;
    }

.imageCarousel .disabled {
    color: dimgray;
}

.imageCarousel .disabled:hover {
    color: green;
}




.imageCarousel .primary {
    color: gold;
}

    .imageCarousel .primary:hover {
        color: #ff3939;
    }


/* Not used currently https://codepen.io/Bung86/pen/PoZxwBV
    a:hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";*/ /* IE 8 */
    /*filter: alpha(opacity=50);*/ /* IE7 */
    /*opacity: 0.6;
    text-decoration: none;
}*/